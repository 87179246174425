
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
    const [status, setStatus] = useState(false);
    return (
        <nav className={"page-links" + (status ? " menu-open" : " menu-close")}>
            <button type="button" className="menu-toggle" onClick={() => setStatus(!status)}>
                <span className="sr-only">Open sidebar</span>
                {status === false && <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" className="icon-lg text-black" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>}

                {status === true && <svg stroke="currentColor" fill="none" viewBox="0 0 24 24" className="icon-lg text-black" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>}
            </button>

            <Link className="menu-item" to="/about" onClick={()=>setStatus(false)}>About</Link>
            <Link className="menu-item" to="/contact" onClick={()=>setStatus(false)}>Contact Us</Link>
            <Link className="menu-item" to="/cookie" onClick={()=>setStatus(false)}>Cookies Policy</Link>
            <Link className="menu-item" to="/terms" onClick={()=>setStatus(false)}>Terms of Service</Link>
        </nav>
    )
}
export default Menu