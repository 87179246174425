import { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {
    FormControl,
    Input,
    InputLabel,
    Button,
    TextField,
} from '@mui/material';

export default function Contact() {
    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [description, setDescription] = useState("");
    return (
        <main>
            <div className="bg1">
                <div className="bg2">
                    <Header />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="about-box">
                                    <h1>Contact Us</h1>
                                    <form
                                        className="form"
                                        id="contact-form"
                                        action="mailto:support@checkinternetspeed.co.uk"
                                        method="post"
                                        encType="text/plain"
                                    >
                                        <FormControl>
                                            <InputLabel htmlFor="my-input">
                                                Email address
                                            </InputLabel>
                                            <Input
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel htmlFor="my-input">
                                                First Name
                                            </InputLabel>
                                            <Input
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                                value={firstname}
                                                onChange={(e) => setFirstname(e.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel htmlFor="my-input">
                                                Last Name
                                            </InputLabel>
                                            <Input
                                                id="my-input"
                                                aria-describedby="my-helper-text"
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                id="outlined-multiline-static"
                                                label="Description"
                                                multiline
                                                rows={4}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <Button
                                                id="btn-contact"
                                                type="submit"
                                                size="large"
                                                variant="contained"
                                            >
                                                <a href={`mailto:you@adress.com?subject=${'From ' + firstname + ' ' + lastname + ', Email address: ' + email}&body=${description}`}>Contact Us</a>
                                            </Button>
                                        </FormControl>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}
