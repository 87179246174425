import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CitySection from '../components/CitySection';
import Button from '@mui/material/Button';
import axios from 'axios';
const serverUrl = process.env.REACT_APP_SERVER_URL;

const SpeedCheck = () => {
    const { city } = useParams();

    const [cityInfo, setCityInfo] = useState({});
    const [aiLoading, setAiLoading] = useState(false);

    const loadCityInfo = () => {
        axios(`${serverUrl}/api/city/cityinfo/${city}`)
            .then((res) => {
                let description = res.data.description;
                if (description === "No information") {
                    res.data.description = "Loading...";
                    handleAIGenerate();
                }
                res.data.description = `<p>${res.data.description
                    .split('\n')
                    .join('</p><p>')}</p>`;
                setCityInfo(res.data);
            })
            .catch((err) => console.log(err));
    };

    const handleAIGenerate = () => {
        if (aiLoading === true) return;
        setAiLoading(true);
        axios
            .get(`${serverUrl}/api/city/citydes/${cityInfo.name}`)
            .then((res) => {
                let newCityInfo = res.data;
                let description = `<p>${newCityInfo.description
                    .split('\n')
                    .join('</p><p>')}</p>`;
                setCityInfo({ ...newCityInfo, description });
                setAiLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setAiLoading(false);
            });
    };
    
    useEffect(() => {
       loadCityInfo();
    }, []);
    
    useEffect(() => {
        loadCityInfo();
        document.title = `Check Internet Speed | Check Internet Speed in ${city.charAt(0).toUpperCase() + city.slice(1)} | Check Internet Speed with checkinternetspeed.co.uk`;
    }, [city]);
    return (
        <main id="speed-page">
            
            <div className="bg1">
                <div className="bg2">
                <Header />
                    <h1>Check Internet Speed in {cityInfo.name}</h1>
                    <div id="properties">
                        <span>Population: {cityInfo.population}</span>
                        <span>Speed: {cityInfo.speed}</span>
                    </div>
                    <div id="description">
                        <Button
                            className="btn-load-ai"
                            onClick={handleAIGenerate}
                            startIcon={
                                <img
                                    src={'/icon-ai.png'}
                                    id="icon-ai"
                                    className={aiLoading ? 'loading' : ''}
                                    alt="icon-loading"
                                />
                            }
                        >
                            Click here to load new information
                        </Button>
                        <div
                            id="text-section"
                            dangerouslySetInnerHTML={{
                                __html: aiLoading
                                    ? '<p>Loading...</p>'
                                    : cityInfo.description,
                            }}
                        ></div>
                    </div>

                    <CitySection />
                </div>
            </div>
            <Footer />
        </main>
    );
};
export default SpeedCheck;
