import React, { Suspense } from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import RoutesTree from './components/RoutesTree';

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <div className="App">
                <RoutesTree />
            </div>
        </Suspense>
    );
}

function Loading() {
    return <h2>🌀 Loading...</h2>;
  }
export default App;