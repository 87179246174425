import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Terms() {
    return (
        <main>
            <div className="bg1">
                <div className="bg2">
                    <Header />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="about-box">
                                    <h1>Terms of Service</h1>
                                    <p>
                                        These terms of service govern
                                        your access and use of the website
                                        checkinternetspeed.co.uk.
                                        The Website is owned and operated by
                                        Outbound BPO Ltd. By using the Website, you agree
                                        to be bound by these Terms. If you do
                                        not agree to these Terms, you must not
                                        use the Website.
                                    </p>
                                    <h3>Services</h3>
                                    <p>
                                        The Website provides you with a service
                                        that allows you to test and compare your
                                        internet speed across the UK. The Service is provided for
                                        informational purposes only and does not
                                        guarantee the accuracy, completeness, or
                                        reliability of the results. The Service
                                        may depend on various factors, such as
                                        your device, browser, network, location,
                                        and provider, that are beyond our
                                        control. We are not responsible for any
                                        errors, omissions, delays, or
                                        interruptions in the Service or the
                                        results. 
                                    </p>
                                    <h3>Licence</h3>
                                    <p>
                                        We grant you a limited, non-exclusive,
                                        non-transferable, and revocable licence
                                        to access and use the Service for your
                                        personal and non-commercial use only.
                                        You must not use the Service for any
                                        other purpose or in any manner that
                                        violates these Terms or any applicable
                                        laws or regulations. You must not copy,
                                        modify, distribute, sell, rent, lease,
                                        or sublicense the Service or any part of
                                        it. You must not interfere with or
                                        disrupt the Service or its security
                                        features. You must not use any automated
                                        means, such as bots, scripts, or
                                        crawlers, to access or use the Service.
                                    </p>
                                    <h3>Intellectual Property</h3>
                                    <p>
                                        We own and retain all rights, title, and
                                        interest in and to the Service and its
                                        content, including but not limited to
                                        the Website design, layout, graphics,
                                        logos, trademarks, data, software, and
                                        code. The Content is
                                        protected by intellectual property laws
                                        and treaties. You do not acquire any
                                        ownership or licence rights in or to the
                                        Content, except as expressly granted in
                                        these Terms.
                                    </p>
                                    <h3>Privacy</h3>
                                    <p>
                                        We respect your privacy and are
                                        committed to protecting your personal
                                        data. Please read our privacy policy, which is
                                        incorporated into these Terms by
                                        reference. The Privacy Policy explains
                                        how we collect, use, store, and share
                                        your personal data when you use the
                                        Service.
                                    </p>
                                    <h3>Disclaimer</h3>
                                    <p>
                                        The Service is provided "as is" and "as
                                        available" without any warranties of any
                                        kind, either express or implied. To the
                                        fullest extent permitted by law, we
                                        disclaim all warranties, conditions, and
                                        representations of any kind, whether
                                        statutory, contractual, tortious, or
                                        otherwise, including but not limited to
                                        the warranties of merchantability,
                                        fitness for a particular purpose,
                                        accuracy, completeness, reliability,
                                        availability, security, or
                                        non-infringement.
                                    </p>
                                    <p>
                                        We do not warrant that the Service will
                                        meet your requirements or expectations,
                                        or that it will be uninterrupted,
                                        error-free, or free of viruses or other
                                        harmful components.
                                    </p>
                                    <p>
                                        We do not warrant that the results
                                        obtained from the Service will be
                                        accurate, complete, or reliable.
                                    </p>
                                    <p>
                                        You acknowledge and agree that your use
                                        of the Service is at your own risk and
                                        discretion, and that you are solely
                                        responsible for any damage or loss that
                                        may result from your use of the Service.
                                    </p>
                                    <h3>Limitation of Liability</h3>
                                    <p>
                                        To the fullest extent permitted by law,
                                        we shall not be liable for any direct,
                                        indirect, incidental, special,
                                        consequential, or exemplary damages,
                                        including but not limited to damages for
                                        loss of profits, revenue, data,
                                        goodwill, or other intangible losses,
                                        arising out of or in connection with
                                        your access or use of or inability to
                                        access or use the Service, even if we
                                        have been advised of the possibility of
                                        such damages.
                                    </p>
                                    In no event shall our total liability to you
                                    for all claims arising out of or relating to
                                    these Terms or the Service exceed £100.
                                    <h3>Indemnification</h3>
                                    <p>
                                        You agree to indemnify, defend, and hold
                                        harmless us and our affiliates,
                                        directors, officers, employees, agents,
                                        and contractors from and against any and
                                        all claims, liabilities, damages,
                                        losses, costs, and expenses (including
                                        reasonable legal fees) arising out of or
                                        in connection with your breach of these
                                        Terms or your access or use of or
                                        inability to access or use the Service.
                                    </p>
                                    <h3>Termination</h3>
                                    <p>
                                        We reserve the right to terminate your
                                        access and use of the Service at any
                                        time and for any reason without notice
                                        or liability. We may also suspend or
                                        disable your access and use of the
                                        Service if we suspect that you have
                                        violated these Terms or any applicable
                                        laws or regulations. Upon termination of
                                        these Terms for any reason,
                                    </p>
                                    <p>
                                        • Your licence to access and use the
                                        Service will immediately cease;
                                    </p>
                                    <p>
                                        • You will lose access to any data or
                                        information that you have stored on the
                                        Service;
                                    </p>
                                    <p>
                                        • We may delete any data or information
                                        that you have stored on the Service; and
                                    </p>
                                    <p>
                                        • You will remain liable for any
                                        obligations that you have incurred under
                                        these Terms prior to termination.
                                    </p>
                                    <h3>Changes</h3>
                                    <p>
                                        We may change these Terms at any time
                                        and for any reason by posting an updated
                                        version on the Website. The updated
                                        Terms will become effective immediately
                                        upon posting. Your continued access or
                                        use of the Service after the effective
                                        date of the updated Terms will
                                        constitute your acceptance of the
                                        updated Terms. If you do not agree to
                                        the updated Terms, you must stop
                                        accessing or using the Service.
                                    </p>
                                    <h3>Miscellaneous</h3>
                                    <p>
                                        These Terms constitute the entire
                                        agreement between you and us regarding
                                        your access and use of the Service and
                                        supersede any prior or contemporaneous
                                        agreements, communications, or
                                        understandings, whether oral or written.
                                        These Terms are governed by and
                                        construed in accordance with the laws of
                                        England and Wales, without regard to its
                                        conflict of law principles. You agree to
                                        submit to the exclusive jurisdiction of
                                        the courts of England and Wales for any
                                        disputes arising out of or relating to
                                        these Terms or the Service. If any
                                        provision of these Terms is held to be
                                        invalid or unenforceable by a court of
                                        competent jurisdiction, such provision
                                        shall be modified to the extent
                                        necessary to make it valid and
                                        enforceable, and the remaining
                                        provisions shall remain in full force
                                        and effect. Our failure to enforce any
                                        right or provision of these Terms shall
                                        not be deemed a waiver of such right or
                                        provision. You may not assign or
                                        transfer your rights or obligations
                                        under these Terms without our prior
                                        written consent. We may assign or
                                        transfer our rights or obligations under
                                        these Terms without your consent and
                                        without notice.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}
