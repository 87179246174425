import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ReplayIcon from '@mui/icons-material/Replay';
import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function CitySection() {
    // auto complete city section

    const navigate = useNavigate();

    const [cities, setCities] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [ajaxLoading, setAjaxLoading] = useState(false);

    const loading = open && options.length === 0;

    const handleSelectCity = (e, newValue) => {
        if (!newValue) return;
        navigate(
            `/speedcheck/${
                newValue.slug !== '' ? newValue.slug : newValue.name
            }`
        );
    };
    const loadData = async () => {
        if (ajaxLoading) return;
        setAjaxLoading(true);
        await axios
            .get(`${serverUrl}/api/city/citylist/${cities.length}`)
            .then((res) => {
                setCities([...cities, ...res.data.cities]);
            })
            .catch((err) => console.log(err));
        setAjaxLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleKeyUp = (e) => {
        axios
            .get(`${serverUrl}/api/city/autocomplete?keyword=${e.target.value}`)
            .then((res) => {
                setOptions(res.data);
            })
            .catch((err) => console.log(err));
    };

    const handleScroll = (e) => {
        let scrollableElement = e.target;
        if (
            scrollableElement.scrollHeight - scrollableElement.scrollTop ===
            scrollableElement.clientHeight
        ) {
            // We've reached the end of the scrollable content
            loadData();
        }
    };

    // auto complete city section
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                axios
                    .get(`${serverUrl}/api/city/autocomplete?keyword`)
                    .then((res) => {
                        setOptions(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Box id="city-select-section">
            <Autocomplete
                id="city-select"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={handleSelectCity}
                isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onKeyUp={handleKeyUp}
                        label="Select city..."
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <div id="city-section-box">
                <Box
                    id="city-section"
                    onScroll={handleScroll}
                >
                    {cities.map((item) => (
                        <Button
                            key={item.name}
                            variant="outlined"
                            className="city-item"
                        >
                            <Link
                                to={`/speedcheck/${
                                    item.slug !== '' ? item.slug : item.name
                                }`}
                            >
                                {item.name}
                            </Link>
                        </Button>
                    ))}
                </Box>
                {ajaxLoading && <ReplayIcon className="active" />}
            </div>
        </Box>
    );
}
