import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function About() { 

    return (
        <main>
            <div className="bg1">
                <div className="bg2">
                    <Header />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="about-box">
                                    <h1>About us</h1>
                                    <p>Welcome to Check Internet Speed, the website that helps you test and compare your internet speed across the UK. We are a team of experts who are passionate about providing you with the best and most accurate information about your internet connection.</p>
                                    

                                    <p>We know how important it is to have a fast and reliable internet service, whether you use it for work, education, entertainment, or communication. That's why we have created this website, where you can easily check your internet speed and see how it compares to other towns and cities in the UK.</p>

                                    <p>Our website uses a sophisticated algorithm that measures your download speed, upload speed, and ping time. These are the key factors that determine the quality and performance of your internet connection. You can check your internet speed by clicking on the "Start Test" button on our homepage. The test will take only a few seconds and will show you your results in a clear and simple format.</p>

                                    <p>You can also compare your internet speed to other locations in the UK by using our interactive map. You can zoom in and out, drag and drop, and click on any town or city to see its average internet speed. You can also filter the results by provider, technology, or speed range. This way, you can see how your internet speed ranks among other users and find out which areas have the best or worst internet service in the UK.</p>

                                    <p>We hope you find our website useful and informative. We are constantly updating our data and improving our features to provide you with the most accurate and up-to-date information about your internet speed. If you have any questions, suggestions, or feedback, please feel free to contact us at support@checkinternetspeed.co.uk. We would love to hear from you.</p>
                                    
                                    <p>Thank you for choosing Check Internet Speed. We hope you enjoy our service and have a great online experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}
