import React from 'react'   ;
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Terms from '../pages/Terms';
import Cookie from '../pages/Cookie';
import SpeedCheck from '../pages/SpeedCheck';

const RoutesTree = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/cookie" element={<Cookie />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/speedcheck/:city" element={<SpeedCheck />} />
            </Routes>
        </Router>
    )
}
export default RoutesTree;