import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Cookie() {
    return (
        <main>
            <div className="bg1">
                <div className="bg2">
                    <Header />

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="about-box">
                                    <h1>Cookies Policy</h1>

                                    <p>
                                        We use cookies on our website to enhance
                                        your browsing experience and to provide
                                        you with relevant and personalised
                                        content. Cookies are small files that
                                        are stored on your device when you visit
                                        our website. They help us to remember
                                        your preferences, such as your language,
                                        location, and search history. They also
                                        help us to measure and analyse how you
                                        use our website, such as the pages you
                                        visit, the links you click, and the time
                                        you spend on our website. We use this
                                        information to improve our website and
                                        to tailor our services to your needs and
                                        interests.
                                    </p>

                                    <p>
                                        We respect your privacy and your choice
                                        regarding cookies. You can manage your
                                        cookie settings at any time by clicking
                                        on the "Cookie Settings" button at the
                                        bottom of our website. You can choose to
                                        accept or reject all cookies, or to
                                        customise your preferences for different
                                        types of cookies, such as:
                                    </p>
                                    <p>
                                        • Essential cookies: These cookies are
                                        necessary for the basic functionality of
                                        our website, such as security,
                                        authentication, and navigation. You
                                        cannot opt out of these cookies, as they
                                        are required for our website to work
                                        properly.
                                    </p>
                                    <p>
                                        • Performance cookies: These cookies
                                        collect anonymous data about how you use
                                        our website, such as the number of
                                        visitors, the sources of traffic, and
                                        the pages viewed. We use these cookies
                                        to monitor and improve the performance
                                        and quality of our website.
                                    </p>
                                    <p>
                                        • Functional cookies: These cookies
                                        enable additional features and services
                                        on our website, such as social media
                                        integration, video streaming, and online
                                        chat. These cookies may be set by us or
                                        by third-party providers whose services
                                        we have added to our website.
                                    </p>
                                    <p>
                                        • Targeting cookies: These cookies track
                                        your online activity and behaviour
                                        across different websites and platforms.
                                        They are used to deliver relevant and
                                        personalised advertising and content to
                                        you based on your interests and
                                        preferences.
                                    </p>
                                    <p>
                                        You can find more information about the
                                        cookies we use and how we process your
                                        personal data in our privacy policy and
                                        cookie policy. By clicking "Accept All",
                                        you consent to the use of all cookies on
                                        our website. By clicking "Customise",
                                        you can select which types of cookies
                                        you want to accept or reject. You can
                                        change your cookie settings at any time
                                        by visiting our cookie settings page.
                                    </p>
                                    <p>
                                        Thank you for choosing Check Internet
                                        Speed. We hope you enjoy our service and
                                        have a great online experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </main>
    );
}
