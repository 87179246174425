import { useNavigate } from 'react-router-dom';
import Menu from './Menu';

export default function Header() {
    const navigate = useNavigate();
    return (
        <header>
            <img src="/logo.png" alt="logo" onClick={() => navigate(`/`)} />

            <Menu />
        </header>
    );
}
