import Menu from './Menu';

export default function Footer() {
    return (
        <footer>
            <span>©2023 Checkinternetspeed.co.uk</span>

            <Menu />
        </footer>
    );
}
